import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
  DxDataGridModule,
  DxButtonModule,
  DxToastModule,
  DxTabsModule,
  DxPivotGridModule,
  DxNumberBoxModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxCheckBoxModule,
  DxLookupModule,
  DxDrawerModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTagBoxModule,
  DxFormModule,
  DxAutocompleteModule,
  DxButtonGroupModule,
  DxPopupModule,
  DxToolbarModule,
  DxLoadPanelModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxScrollViewModule,
  DxDropDownBoxModule,
  DxListModule,
  DxTreeViewModule,
  DxRadioGroupModule,
  DxMenuModule,
  DxFileUploaderModule,
} from 'devextreme-angular';

// import { from } from 'rxjs';

import { AceEditorModule } from 'ng2-ace-editor';

import { ScrollTabsComponent } from './devextreme/scroll-tabs/scroll-tabs.component';

import { DataShareService } from '../core/services/datashare.service';

import { ScrollSpyDirective } from '../directives/scroll-spy.directive';

import { ExcelService } from '../core/services/excel.service';

import { PdfService } from '../core/services/pdf.service';

// basic directive
import { Wizard2Component } from './wizard2/wizard2.component';
import { Wizard4Component } from './wizard4/wizard4.component';

import { InputComponent } from './input/input.component';

import { FormContainerComponent } from './form-container/form-container.component';

import { FormFootComponent } from './form-foot/form-foot.component';

import { ProgressBarComponent } from './progress-bar/progress-bar.component';

/*

import { RadioComponent } from "./radio/radio.component";

import { RatingComponent } from "./rating/rating.component";



import { RestaurantService } from "../restaurant/restaurant.service";

import { ShoppingCartService } from "../restaurant-detail/shopping-cart/shopping-cart.service";

import { OrderService } from "../order/order.service";

import { SnackbarComponent } from "./messages/snackbar/snackbar.component";

import { NotificationService } from "./messages/notification.service";

import { LoginService } from "../security/login/login.service";

import { LoggedInGuard } from "../security/loggedin.guard";

import { LeaveOrderGuard } from "../order/leave-order.guard";

*/

// to work with interceptors need this dependence

// import { ButtonComponent } from "./button/button.component";

import { RadioCheckboxComponent } from './radiocheckbox/radiocheckbox.component';

import { RadioCheckListComponent } from './radio-check-list/radio-check-list.component';

import { SelectComponent } from './select/select.component';

import { FormContentComponent } from './form-content/form-content.component';

import { GenericService } from '../core/services/generic.service';

// import { NgSelect2Module } from "ng-select2";

// import { AuthInterceptor } from "../security/auth.interceptor";

import { GridComponent } from './devextreme/grid/grid.component';

import { TabsComponent } from './devextreme/tabs/tabs.component';

import { PopupComponent } from './devextreme/popup/popup.component';

import { FrmComponent } from './devextreme/frm/frm.component';

import { ButtonComponent } from './devextreme/button/button.component';

import { RulesComponent } from '../../pages/rules/rules.component';

import { DomainOrderComponent } from '../../pages/domain-order/domain-order.component';

import { RulesService } from '../../pages/rules/rules.service';

import { DomainOrderService } from '../../pages/domain-order/domain-order.service';
import { DualListboxComponent } from './dual-listbox/dual-listbox.component';
import { ListComponent } from './list/list.component';
import { List2Component } from './list2/list2.component';
import { AxiosService } from '../core/services/axios.service';
import { initializeKeycloak } from 'src/app/init/keycloak-init.factory';
import { CollapseComponent } from './collapse/collapse.component';
import { GrpformComponent } from './grpform/grpform.component';

//import { KeycloakAngularModule } from 'keycloak-angular';
//import { AuthInterceptor } from 'src/app/modules/auth/auth.interceptor';

@NgModule({
  declarations: [

    InputComponent,
    Wizard2Component,
    Wizard4Component,
    GrpformComponent,
    DualListboxComponent,
    FormContainerComponent,
    CollapseComponent,
    FormFootComponent,
    ListComponent,
    List2Component,
    ProgressBarComponent,

    RadioCheckboxComponent,
    RulesComponent,
    ButtonComponent,

    SelectComponent,

    FormContentComponent,

    RadioCheckListComponent,

    GridComponent,

    TabsComponent,

    PopupComponent,

    FrmComponent,

    ButtonComponent,

    ScrollTabsComponent,

    ScrollSpyDirective,

    // RulesComponent,

    DomainOrderComponent,

    /* RadioComponent,

		RatingComponent,

		SnackbarComponent */
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

  imports: [
    DxDataGridModule,
    KeycloakAngularModule,
    DxButtonModule,
    DxPivotGridModule,
    DxToastModule,
    DxFileUploaderModule,
    DxTabsModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxTreeViewModule,
    
    DxDropDownBoxModule,
    DxLookupModule,

    DxTextBoxModule,

    DxDateBoxModule,

    DxToolbarModule,

    DxSelectBoxModule,

    DxTabPanelModule,

    DxCheckBoxModule,

    DxTemplateModule,

    DxTextAreaModule,

    DxNumberBoxModule,

    DxTagBoxModule,

    DxFormModule,

    DxRadioGroupModule,

    DxDrawerModule,

    DxListModule,

    DxMenuModule,

    DxButtonGroupModule,

    DxPopupModule,

    DxLoadPanelModule,
    DxAutocompleteModule,
    DxScrollViewModule,

    CommonModule,

    FormsModule,

    ReactiveFormsModule,

    AceEditorModule,
  ],

  exports: [
    GridComponent,
    ListComponent,
    List2Component,
    CollapseComponent,
    TabsComponent,
    RulesComponent,
    GrpformComponent,
    InputComponent,
    Wizard2Component,
    Wizard4Component,
    DualListboxComponent,
    DxLookupModule,
    DxAutocompleteModule,
    DxMenuModule,
    DxPivotGridModule,
    DxTextBoxModule,

    DxCheckBoxModule,

    DxButtonModule,

    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxDateBoxModule,

    DxTreeViewModule,

    DxLoadPanelModule,

    DxDataGridModule,

    DxDrawerModule,

    DxRadioGroupModule,

    DxToolbarModule,

    DxSelectBoxModule,

    DxTagBoxModule,

    DxNumberBoxModule,

    DxListModule,

    DxScrollViewModule,

    FormContainerComponent,

    ButtonComponent,

    SelectComponent,

    FormContentComponent,

    RadioCheckboxComponent,

    RadioCheckListComponent,

    FormFootComponent,

    ProgressBarComponent,

    ReactiveFormsModule,

    FrmComponent,

    // RulesComponent,

    DomainOrderComponent,

    PopupComponent,

    ButtonComponent,

    ScrollTabsComponent,

    ScrollSpyDirective,

    FormsModule /* ,		RadioComponent,

		RatingComponent,

		SnackbarComponent,

		CommonModule,



		 */,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,

      providers: [
        GenericService,
        //AxiosService,
        DataShareService,

        ExcelService,

        PdfService,

        RulesService,
        {
          provide: APP_INITIALIZER,
          useFactory: initializeKeycloak,
          multi: true,
          deps: [KeycloakService],
        },
        DomainOrderService,
        /*{
					provide: HTTP_INTERCEPTORS,
					useClass: AuthInterceptor,
					multi: true
				}*/
        /* OrderService

					ShoppingCartService,

				RestaurantService,

				NotificationService,

				LoginService,

				LoggedInGuard,

				LeaveOrderGuard, //criar classe, declarar como provider e associar na rota

			 */

        // multi=true => to receive multi values
      ],
    };
  }
}
