<!-- Begin: Aside Menu -->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
  <ng-container >
    <!-- Begin: Header Logo -->
    <div class="centered-logo">
    <a routerLink="/">
      <img alt="Logo" [attr.src]="headerLogo" />
    </a>
  </div>
    <!-- End: Header Logo -->
  </ng-container>

  <!-- Begin: Search Input -->
  <div>
    <input 
      type="text" 
      placeholder="Pesquisar..."
      (input)="searchQuery = $event.target.value"  
      class="form-control transparent-search" 
      autofocus
    />
  </div>
  <!-- End: Search Input -->

  <!-- Begin: Menu Container -->
  <div
    id="kt_aside_menu"
    class="aside-menu"
    [ngClass]="asideMenuCSSClasses"
    data-menu-vertical="1"
    [attr.data-menu-scroll]="asideMenuScroll"
    [attr.data-menu-dropdown]="asideMenuDropdown"
    data-menu-dropdown-timeout="500"
  >
    <!-- Begin: Menu Navigation -->
    <ul class="menu-nav" [ngClass]="ulCSSClasses">
      <!-- Loop through items -->

<!-- ========================  -->

      
      <ng-container *ngFor="let child of items; let i = index">
        
        <!-- Check if the section should be displayed -->
        <ng-container *ngIf="child.section || 
          (child.translate && child.translate.toLowerCase().includes(searchQuery.toLowerCase())) || 
          (child.submodel && child.submodel.toLowerCase().includes(searchQuery.toLowerCase()))">
        
          
          <!-- Render section if exists -->
          <li (click)="clickMenu(child.collapsed=!child.collapsed, i)" class="menu-section" *ngIf="child.section">
            <h4 class="menu-text menu-text-section">{{ child.section }}</h4>
            <i class="menu-icon flaticon-more-v2"></i> 
          </li>

          <!-- Render normal item if no submodel -->
          <li *ngIf="!child.section && !child.submodel" [ngClass]="child.cssClass" aria-haspopup="true" routerLinkActive="menu-item-active">
            <a class="menu-link" routerLink="{{ child.page }}" routerLinkActive="active">
              <i class="svg-icon menu-icon fa {{ child.icon }}"></i> <!-- Certifique-se de que child.icon está correto -->
              <span class="menu-text">{{ child.translate }}</span>
            </a>
          </li>

          <!-- Render submodel item with optional submodel2 -->
          <li *ngIf="child.submodel" [ngClass]="child.cssClass" aria-haspopup="true" data-menu-toggle="hover">
            <a href="javascript:;" class="menu-link menu-toggle">
              <span class="svg-icon menu-icon">
                <i class="svg-icon menu-icon fa {{ child.icon }}"></i>
              </span>
              <span class="menu-text">{{ child.submodel }}</span>
              <i class="menu-arrow"></i>
            </a>

            <!-- Begin: Submodel submenu -->
            <div class="menu-submenu" [ngClass]="{'show': child.collapsed}">
              <i class="menu-arrow"></i>
              <ul class="menu-subnav">

                <!-- Render list of items under submodel -->
                <ng-container *ngFor="let m of child.list">
                  <li *ngIf="m.submodel2" class="menu-item" aria-haspopup="true" (click)="clickSubmodel2(m)">
                    <a href="javascript:;" class="menu-link menu-toggle">
                      <span class="menu-text">{{ m.submodel2 }}</span>
                      <i class="menu-arrow"></i>
                    </a>

                    <!-- Begin: Submodel2 submenu -->
                    <div class="menu-submenu" [ngClass]="{'show': m.showSubmodel2}">
                      <i class="menu-arrow"></i>
                      <ul class="menu-subnav">
                        <ng-container *ngFor="let m2 of m.list2">
                          <li *ngIf="m2.translate" class="menu-item" aria-haspopup="true">
                            <a routerLink="{{ m2.page }}" class="menu-link">
                              <i class="menu-bullet menu-bullet-dot">
                                <span></span>
                              </i>
                              <span class="menu-text">{{ m2.translate }}</span>
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                    <!-- End: Submodel2 submenu -->

                  </li>
                  
                  <li *ngIf="!m.submodel2" class="menu-item" aria-haspopup="true">
                    <a routerLink="{{ m.page }}" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">{{ m.translate }}</span>
                    </a>
                  </li>
                </ng-container>

              </ul>
            </div>
            <!-- End: Submodel submenu -->

          </li>

        </ng-container>

      </ng-container>


<!-- ========================  -->



    </ul>
    <!-- End: Menu Navigation -->

  </div>
  <!-- End: Menu Container -->
</div>
<!-- End: Aside Menu -->