<div class="m-5">
  <!-- <ngs-code-editor
  theme="vs-dark"
  readOnly="readOnly"
  [(value)]="code"
  language="typescript"
  [options]="options"
  (valueChanged)="onCodeChanged($event)">
</ngs-code-editor> -->
  <!--
  <ace-editor [(text)]="code" (textChanged)="getValue($event)" [mode]="'javascript'" [theme]="'eclipse'" #editor
    style="height:150px;width: 500px;">
  </ace-editor>
-->

  <div class="row">
    <div
      class="pb-5 {{ !!sc.colspan ? sc.colspan : 'col-6' }}"
      [ngClass]="{ 'd-none': !sc.formVisibility }"
      *ngFor="let sc of screen"
      [ngSwitch]="sc.editorType"
    >
      <!-- the same view can be shown in more than one case -->
      <div class="dx-field" *ngSwitchCase="'dxTextBox'">
        <div class="dx-field-label">{{ sc.caption }} :</div>
        <div class="dx-field-value">
          <dx-text-box
            [(value)]="formdata[sc.dataField]"
            [(visible)]="sc.formVisibility"
          ></dx-text-box>
        </div>
      </div>
      <div class="dx-field" *ngSwitchCase="'dxCheckBox'">
        <div class="option">
          <div class="dx-field-label">
            <dx-check-box
              text="{{ sc.caption }}"
              [(value)]="formdata[sc.dataField]"
              [(visible)]="sc.formVisibility"
            >
            </dx-check-box>
          </div>
        </div>
      </div>
      <div class="dx-field" *ngSwitchCase="'dxDateBox'">
        <div class="dx-field-label">{{ sc.caption }} :</div>
        <div class="dx-field-value">
          <dx-date-box
            [validationRules]="sc?.validatorRules"
            [(value)]="formdata[sc?.dataField]"
            [min]="formdata[sc?.min]"
            [max]="formdata[sc?.max]"
            type="date"
            pickerType="calendar"
            displayFormat="monthAndYear"
            [calendarOptions]="{
              maxZoomLevel: 'year',
              minZoomLevel: 'century'
            }"
            [(visible)]="sc.formVisibility"
          >
          </dx-date-box>
        </div>
      </div>
      <div class="dx-field" *ngSwitchCase="'dxComboBox'">
        <div class="dx-field-label">{{ sc.caption }} :</div>
        <div class="dx-field-value">
          <dx-drop-down-box
            [(value)]="formdata[sc?.dataField]"
            valueExpr="ID"
            displayExpr="name"
            placeholder="Select a value..."
            [showClearButton]="true"
            [dataSource]="treeDataSource"
            (onValueChanged)="syncTreeViewSelection($event)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                [dataSource]="treeDataSource"
                dataStructure="plain"
                keyExpr="ID"
                parentIdExpr="categoryId"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="false"
                displayExpr="name"
                [selectByClick]="true"
                (onContentReady)="syncTreeViewSelection($event)"
                (onItemSelectionChanged)="treeView_itemSelectionChanged($event)"
              >
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>

      <div class="dx-field" *ngSwitchCase="'dxSelectBox'">
        <div class="dx-field-label">{{ sc.caption }} :</div>
        <div class="dx-field-value">
          <dx-drop-down-box
            [(value)]="formdata[sc?.dataField]"
            valueExpr="id"
            displayExpr="name"
            placeholder="{{ sc?.editorOptions.placeholder }}"
            [showClearButton]="true"
            [dataSource]="sc?.editorOptions.dataSource"
            [validationRules]="sc?.validatorRules"
            (onValueChanged)="syncTreeViewSelection1($event, sc?.dataField)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                [dataSource]="sc?.editorOptions.dataSource"
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="categoryId"
                selectionMode="single"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="false"
                displayExpr="name"
                [selectByClick]="true"
                (onContentReady)="syncTreeViewSelection1($event, sc?.dataField)"
                (onItemSelectionChanged)="
                  treeView_itemSelectionChanged1($event, sc?.dataField)
                "
              >
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>

      <div class="dx-field" *ngSwitchCase="'cxCodeEditor'">
        <div class="dx-field-label">{{ sc.caption }} :</div>
        <div class="dx-field-value">
          <button (click)="increaseFont()">A+</button>
           <button (click)="decreaseFont()">A-</button>
          <ace-editor
            class="editor"
            [(text)]="formdata[sc.dataField]"
            (textChanged)="getValue($event)"
            [enableBasicAutocompletion]="true"
            [enableLiveAutocompletion]="true"
            [theme]="'eclipse'"
            [mode]="typeData"
            #editor
          ></ace-editor>
        </div>
      </div>
      <!--default case when there are no matches -->
      <div class="dx-field" *ngSwitchDefault>
        <div
          class="dx-field-label"
          [ngClass]="{ 'd-none': !sc.formVisibility }"
        >
          {{ sc.caption }} :
        </div>
        <div class="dx-field-value">
          <dx-text-box
            [(value)]="formdata[sc.dataField]"
            [(visible)]="sc.formVisibility"
          ></dx-text-box>
        </div>
      </div>
    </div>
  </div>
  <div
    class="
      dx-form-validation-summary dx-validationsummary dx-widget dx-collection
    "
    *ngIf="formAttempted"
  >
    <div
      *ngFor="let error of customValidationErrors"
      class="dx-item dx-validationsummary-item"
    >
      <div class="dx-item-content dx-validationsummary-item-content">
        {{ error.message }}
      </div>
    </div>
  </div>
  <!--<div class="row">
    <div class="col-6 text-center">
      <dx-button
        stylingMode="contained"
        text="Cancelar"
        type="normal"
        [width]="120"
        (onClick)="cancelClick($event)"
      >
      </dx-button>
    </div>
    <div class="col-6 text-center">
      <dx-button
        stylingMode="contained"
        text="Salvar"
        type="normal"
        [width]="120"
        (onClick)="saveClick($event)"
      >
      </dx-button>
    </div>
  </div> -->
</div>


<div class="fixed-footer">
  <div class="row">
    <div class="col-6 text-center">
      <dx-button
        stylingMode="contained"
        text="Cancelar"
        type="normal"
        [width]="120"
        (onClick)="cancelClick($event)"
      ></dx-button>
    </div>
    <div class="col-6 text-center">
      <dx-button
        stylingMode="contained"
        text="Salvar"
        type="normal"
        [width]="120"
        (onClick)="saveClick($event)"
      ></dx-button>
    </div>
  </div>
</div>