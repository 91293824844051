import {
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard2 } from './guard/auth-guard';
import { AuthGuard } from './modules/auth/_services/auth.guard';
import { LayoutComponent } from './pages/_layout/layout.component';

const form = import('./pages/form/form.module').then((m) => m.FormModule);

export const routes: Routes = [
  {
    path: 'auth',

    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: '',

    component: LayoutComponent,
    canActivate: [AuthGuard2],
    runGuardsAndResolvers: 'always',
    children: [
      /*{
    path: 'wizard',
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/proccess-wizard/proccess-wizard.module').then(
        (m) => m.ProccessWizardModule
      ),
  },*/
      {
        path: 'people',
        // component: LayoutComponent,
        loadChildren: () =>
          import('./pages/people/people.module').then((m) => m.PeopleModule),
      },

      {
        path: 'base/:page',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/base/base.module').then((m) => m.BaseModule),

        // loadChildren: "./pages/base/base.module#BaseModule",

        /* loadChildren: () =>

      import("../app/pages/base/base.module").then(

        m => m.BaseModule

      ) */
      },
      {
        path: 'absenteeism',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/absenteeism/absenteeism.module').then(
            (m) => m.AbsenteeismModule
          ),

        // loadChildren: "./pages/base/base.module#BaseModule",

        /* loadChildren: () =>

      import("../app/pages/base/base.module").then(

        m => m.BaseModule

      ) */
      },

      {
        path: 'absenteeism/:id',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/absenteeism/edit/absenteeism.module').then(
            (m) => m.AbsenteeismModule
          ),

        // loadChildren: "./pages/base/base.module#BaseModule",

        /* loadChildren: () =>

      import("../app/pages/base/base.module").then(

        m => m.BaseModule

      ) */
      },
       {
        path: 'process-output/report/annual-income-declaration',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/annual-income-declaration/annual-income-declaration.module').then(
            (m) => m.AnnualIncomeDeclarationModule
          ),
      },
      {
        path: 'process-output/report/process-viewer/:param',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/process-viewer/process-viewer.module').then(
            (m) => m.ProcessViewerModule
          ),
      },
      {
        path: 'people/dossier',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/dossier/dossier.module').then(
            (m) => m.DossierModule
          ),
      },
      {
        path: 'people/view-dossier/:param',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/dossier/view-dossier/view-dossier.module').then(
            (m) => m.ViewDossierModule
          ),
      },


     {
        path: 'people/annual-income',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/annual-income/annual-income.module').then(
            (m) => m.AnnualIncomeModule
          ),
      },


     {
        path: 'people/annual-income/:param',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/annual-income/report/view-annual-income.module').then(
            (m) => m.ViewAnnualIncomeModule
          ),
      },


      {
        path: 'process-output/report/:page/:param',

        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/report/report.module').then(
            (m) => m.ReportModule
          ),

        // loadChildren: "./pages/base/base.module#BaseModule",

        /* loadChildren: () =>

      import("../app/pages/base/base.module").then(

        m => m.BaseModule

      ) */
      },
      {
        path: 'form/:page/:id',

        // component: LayoutComponent,

        loadChildren: () => form,
      },

      {
        path: 'form/:page/:id/:id_companies',

        // component: LayoutComponent,

        loadChildren: () => form,
      },

      {
        path: 'dashboard',

        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'form/:page',

        //   component: LayoutComponent,

        loadChildren: () => form,
      },

      {
        path: 'rule/:id',

        // component: LayoutComponent,

        loadChildren: () =>
          import('./pages/rules/rules.module').then((m) => m.RulesModule),

        outlet: 'sidebar',
      },
      {
        path: 'process-output/:param',
        loadChildren: () =>
          import('./pages/process/process-output/process-output.module').then(
            (m) => m.ProcessOutputModule
          ),
      },

   {
        path: 'employee_exits/new',
        loadChildren: () =>
          import('./pages/employee_exits/new/employee-exits-new.module').then(
            (m) => m.EmployeeExitsNewModule
          ),
      },

 {
        path: 'employee_exits/new/:id',
        loadChildren: () =>
          import('./pages/employee_exits/new/employee-exits-new.module').then(
            (m) => m.EmployeeExitsNewModule
          ),
      },

      {
        path: 'employee_exits/:id',
        loadChildren: () =>
          import('./pages/employee_exits/employee-exits.module').then(
            (m) => m.EmployeeExitsModule
          ),
      },
    
      {
        path: 'settingssegsocial',
        loadChildren: () =>
          import('./pages/settings-seg-social/settings-seg-social.module').then(
            (m) => m.SettingsSegSocialModule
          ),
      },
      {
        path: 'process-output/invoice/:param',
        loadChildren: () =>
          import('./pages/process/process-output/invoice/invoice.module').then(
            (m) => m.InvoiceModule
          ),
      },
      {
        path: 'map-discount-allowance/:param',
        loadChildren: () =>
          import(
            './pages/process/process-output/map-discount-allowance/map-discount-allowance.module'
          ).then((m) => m.MapDiscountAllowanceModule),
        // outlet: 'sidebar',
      },
      {
        path: 'map-discount-allowance-list/:param',
        loadChildren: () =>
          import(
            './pages/process/process-output/map-discount-allowance-list/map-discount-allowance-list.module'
          ).then((m) => m.MapDiscountAllowanceListModule),
        // outlet: 'sidebar',
      },
      {
        path: 'domainvalues',

        // component: LayoutComponent,

        loadChildren: () =>
          import('./pages/domain-values/domain-values.module').then(
            (m) => m.DomainValuesModule
          ),
      },
      {
        path: 'profile',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'people/new-person',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/people/newperson/newperson.module').then(
            (m) => m.NewPersonModule
          ),
      },

      {
        path: 'people/new-admission',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/people/newadmission/newadmission.module').then(
            (m) => m.NewAdmissionModule
          ),
      },


       {
        path: 'personal/address',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/people/personal/address/address.module').then(
            (m) => m.AddressModule
          ),
      },

 {
        path: 'companies/new-company',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/companies/newcompany/newcompany.module').then(
            (m) => m.NewCompanyModule
          ),
      },

{
        path: 'companies/update-company/:id',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/companies/newcompany/newcompany.module').then(
            (m) => m.NewCompanyModule
          ),
      },


 {
        path: 'workcenters/new-workcenter',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/workcenters/newworkcenter/newworkcenter.module').then(
            (m) => m.NewWorkcenterModule
          ),
      },

{
        path: 'workcenters/update-workcenter/:id',
        //component: LayoutComponent,

        loadChildren: () =>
          import('./pages/workcenters/newworkcenter/newworkcenter.module').then(
            (m) => m.NewWorkcenterModule
          ),
      },



      {
        path: 'config/roles-table/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import(
            './pages/config/permissions/roles-table/roles-table.module'
          ).then((m) => m.RolesTableModule),
      },
      {
        path: 'config/roles/new',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/config/permissions/roles/roles.module').then(
            (m) => m.RolesModule
          ),
      },
      {
        path: 'config/roles/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/config/permissions/roles/roles.module').then(
            (m) => m.RolesModule
          ),
      },
      {
        path: 'config/groups/new',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/config/permissions/groups/groups.module').then(
            (m) => m.GroupsModule
          ),
      },
      {
        path: 'config/groups/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/config/permissions/groups/groups.module').then(
            (m) => m.GroupsModule
          ),
      },
      {
        path: 'config/users/new',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/config/permissions/user/user.module').then(
            (m) => m.UserModule
          ),
      },
      {
        path: 'config/users/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/config/permissions/user/user.module').then(
            (m) => m.UserModule
          ),
      },
      {
        path: 'workflow/expense',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/expense/expense.module').then(
            (m) => m.ExpenseModule
          ),
      },
      {
        path: 'workflow/personal',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/personal/personal.module').then(
            (m) => m.PersonalModule
          ),
      },
      {
        path: 'workflow/expense/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/expense/expense.module').then(
            (m) => m.ExpenseModule
          ),
      },
      {
        path: 'workflow/timeline',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/timeline/timeline.module').then(
            (m) => m.TimelineModule
          ),
      },
      {
        path: 'expense/approval/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/expense/approval/approval.module').then(
            (m) => m.ApprovalModule
          ),
      },
      {
        path: 'personal/approval/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/personal/approval/approval.module').then(
            (m) => m.ApprovalModule
          ),
      },
      {
        path: 'expense/history/:id',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/expense/history/history.module').then(
            (m) => m.HistoryModule
          ),
      },
      {
        path: 'vacation/periods',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/vacation/periods/periods.module').then(
            (m) => m.PeriodsModule
          ),
      },
      {
        path: 'vacation/validation',
        //component: LayoutComponent,
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./pages/workflow/vacation/validation/periods.module').then(
            (m) => m.PeriodsModule
          ),
      },
      {
        path: 'process',

        //component: LayoutComponent,

        //  loadChildren: () =>

        // import("./pages/process/process.module").then((m) => m.ProcessModule),

        //  loadChildren: './pages/process/process.module#ProcessModule',
        loadChildren: () =>
          import('./pages/process/process.module').then((m) => m.ProcessModule),
      },
      {
        path: 'vacation/plan/:id',
        loadChildren: () =>
          import('./pages/workflow/vacation/vacation-plan/vacation-plan.module').then((m) => m.VacationPlanModule),
      },
      {
        path: 'vacation/people/:id',
        loadChildren: () =>
          import('./pages/workflow/vacation/vacation-people/vacation-people.module').then((m) => m.VacationPeopleModule),
      }, 
      {
        path: 'process/:id',

        // component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process-details/process-details.module').then(
            (m) => m.ProcessDetailsModule
          ),
      },

      {
        path: 'data-management',

        // component: LayoutComponent,

        loadChildren: () =>
          import('./pages/data-management/data-management.module').then(
            //     import('./pages/proccess-wizard/step3/step3.module').then(
            (m) => m.DataManagementModule
          ),
      },

      {
        path: 'btransfer',
        loadChildren: () =>
          import('./pages/btransfer/btransfer.module').then(
            (m) => m.BTransferModule
          ),
        data: {
          breadcrumb: 'Wizard',
        },
      },

      {
        path: 'process-execution/:param',
        loadChildren: () =>
          import('./pages/proccess-wizard/proccess-wizard.module').then(
            (m) => m.ProccessWizardModule
          ),
        data: {
          breadcrumb: 'Wizard',
        },
      },

      {
        path: 'bank-transfer/:param',

        //   component: LayoutComponent,

        loadChildren: () =>
          import('./pages/bank-transfer/bank-transfer.module').then(
            (m) => m.BankTransferModule
          ),
      },
      {
        path: 'process-output/reports/segsocial/:param',

        //  component: LayoutComponent,

        loadChildren: () =>
          import(
            './pages/process/process-output/seguranca-social/seguranca-social.module'
          ).then((m) => m.SegurancaSocialModule),
      },
      {
        path: 'process-output/reports/dmr/:param',

        //  component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/dmr/dmr.module').then(
            (m) => m.DMRModule
          ),
      },
      {
        path: 'process-output/sepa/:param',

        //  component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process/process-output/sepa/sepa.module').then(
            (m) => m.SepaModule
          ),
      },

      {
        path: 'process-paycheck',

        // component: LayoutComponent,

        loadChildren: () =>
          import('./pages/process-paycheck/process-paycheck.module').then(
            (m) => m.ProcessPaycheckModule
          ),
      },

      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: 'error',

    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },

  {
    path: '',

    canActivate: [AuthGuard2],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },

  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
      onSameUrlNavigation: 'reload',
    }),
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],

  exports: [RouterModule],
})
export class AppRoutingModule {}


/**
    "link" : "/form/companies/:id",
    "linknew" : "/companies/new-company/",

    Payment complete
    Your payment reference is 2878501688A00103A
    28/03/2025

    DESCONTO - ABONO PARA FALHAS
    ABSENT_CARACTS

 */